<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="供应商名称" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编码" prop="sn_no">
          <el-input
            size="mini"
            v-model.trim="table.params.sn_no"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">添加打印机</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:bind_type="{row}">
        <span>{{toStr({1:'租赁', 2:'购买', 3:'赠送'},row.bind_type)}}</span>
      </template>
      <template v-slot:is_bind="{row}">
        <span>{{row.is_bind == 1 ? '正常' : '已解绑'}}</span>
      </template>
     
      <template v-slot:action="{row}">
        <el-button v-if="row.is_bind == 1" type="text" @click="handleUnBind(row)">解绑</el-button>
        <template v-else>
          <el-button type="text" @click="showEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleRecoverBind(row)">恢复</el-button>
          <el-button type="text" @click="remove(row)">删除</el-button>
        </template>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" :tableData="table.data" @refresh="getTable"></edit>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'TagprinterIndex',
  components: {
    Edit,
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "name", label: "供应商名称", hidden: false },
        { name: "sn_no", label: "设备编号", hidden: false },
        { name: "bind_type", label: "提供方式", hidden: false},
        { name: "bind_time", label: "创建时间", hidden: false},
        { name: "is_bind", label: "设备状态", hidden: false},
        { name: "action", label: "操作",width: "180", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          is_print:1, //1 只看绑定打印机
          keyword: '',
          sn_no: '',
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      message: false,
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/supplier/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 解绑
    handleUnBind(row) {
      this.setConfirm(
        `解绑后供应商无法使用打印机，您确认解绑吗？`,
        {  id: row.id },
        "/admin/supplier/unBind"
      );
    },
    // 解绑
    handleRecoverBind(row) {
      this.setConfirm(
        `确认恢复操作？`,
        {  id: row.id },
        "/admin/supplier/recoverBind"
      );
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/supplier/delPrint"
      );
    },
  }
}
</script>
