<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑打印机' : '添加打印机'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model="form.data.supplier_id" filterable class="width100">
            <el-option v-for="item in supplierOpt" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编码" prop="sn_no">
          <el-input
            v-model.trim="form.data.sn_no"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定方式" prop="bind_type">
          <el-select v-model="form.data.bind_type" filterable class="width100">
            <el-option label="租赁" :value="1"></el-option>
            <el-option label="购买" :value="2"></el-option>
            <el-option label="赠送" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'TagPrinterEdit',
    props:['tableData'],
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            sn_no: "",
            bind_type: '',
            supplier_id: '',// 供应商id
          },
          rules: {
            supplier_id: [{ required: true, message:'请选择', trigger: 'change'}],
            sn_no: [{ required: true, message:'请输入', trigger: 'change'}],
            bind_type: [{ required: true, message:'请选择', trigger: 'change'}],
          }
        },
        supplierOpt: [], // 供应商
      }
    },
    methods: {
      async getDetail(row) {
        await this.getSupplier();// 供应商
        if(!!row.id) {
          this.isChange = true;
          this.$nextTick(() => {
            this.form.data.supplier_id = row.id; // 供应商id
            this.form.data.sn_no = row.sn_no;
            this.form.data.bind_type = row.bind_type;
          })
        }
      },
      
      // 获取供应商 (启用中的供应商且未绑定过打印机)
      getSupplier() {
        this.$http.get('/admin/supplier/list', {params:{status:1,is_print: 2, page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.supplierOpt = res.data.list;
          }
        })
      },
      // 获取采购员
      getPurchase() {
        this.$http.get('/admin/purchase/list', {params: {page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.purchaseOpt = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.supplier_id,
              sn_no: this.form.data.sn_no,
              bind_type: this.form.data.bind_type,
            };
            this.form.loading = true;
            this.$http.post('/admin/supplier/addPrint', _params).then(res => {
              if(res.code === 1) {
                this.$message.success("操作成功！")
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
